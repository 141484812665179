<template>
  <b-row>
    <b-col cols="12">
      <TableComponents
        v-if="!showForm"
        :columns="columns"
        :rows="listaAgendamentosEmLote"
        sort-name="diaInicial"
        title-table="Agendamentos Em Lote"
        module="agendamentoLoteModule"
        :show-btn-add="true"
        :show-action-delete="false"
        @edit="edit"
        @add="addNew"
      >
        <slot slot="buttons">
          <b-button
            variant="primary"
            class="ml-1"
            data-cy="gerarEmLote"
            @click="gerarEmLote"
          >
            {{ loadingButton ? 'Aguarde...' : ' Gerar Atendimento em Lote' }}
            <b-spinner v-if="loadingButton" small></b-spinner>
          </b-button>
        </slot>
      </TableComponents>
      <Form v-else @cancelar="cancelar" />
    </b-col>
  </b-row>
</template>
<script>
import { mapState } from 'vuex'
import TableComponents from '@/layouts/components/TableComponents.vue'
import { BRow, BCol, BButton, BSpinner } from 'bootstrap-vue'
import Form from '../form/Form.vue'
import mixinsGlobal from '@/mixins'

export default {
  name: 'ContasPagar',
  components: {
    BRow,
    BCol,
    TableComponents,
    Form,
    BButton,
    BSpinner
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('combosModule', {
      comboFormaPagamento: (state) => state.comboFormaPagamento,
      comboTipoPagamento: (state) => state.comboTipoPagamento
    }),
    ...mapState('agendamentoLoteModule', {
      listaAgendamentosEmLote: (state) => state.listaAgendamentosEmLote
    })
  },
  data() {
    return {
      loadingButton: false,
      ehAssociado: false,
      showCpfAssociado: true,
      showForm: false,
      columns: [
        {
          label: 'Dia Inicial',
          field: 'diaInicial'
        },
        {
          label: 'Dia Final',
          field: 'diaFinal'
        },
        {
          label: 'Hora Inicial',
          field: 'horaInicial'
        },
        {
          label: 'Hora Final',
          field: 'horaFinal'
        },
        {
          label: 'Profissional',
          field: 'profissional.nome',
          sortable: false
        },
        {
          label: 'Quantidade Vagas',
          field: 'qtoVagas',
          sortable: false
        },
        {
          label: this.$t('Action'),
          field: 'action'
        }
      ],
      objectFormInsert: {
        titleForm: 'Cadastro de Contas a Pagar'
      }
    }
  },
  methods: {
    async addNew() {
      await this.$store.dispatch('agendamentoLoteModule/reset')
      this.showForm = true
    },
    async edit(data) {
      this.$router.push({ path: `agendamentoEmLote/edit/${data.id}` })
    },
    async cancelar() {
      this.showForm = false
    },
    async gerarEmLote() {
      this.loadingButton = true
      const response = await this.$store.dispatch(
        'agendamentoLoteModule/gerarEmLote'
      )
      if (response.error) {
        this.loadingButton = false
        this.MensagemError(
          'Gerar Atendimento',
          'Falha ao gerar atendimento! :' + response.message
        )
        return
      }
      this.loadingButton = false
      this.showMessageSuccess(
        'Gerar Atendimento',
        'Atendimento gerado com sucesso!'
      )
    }
  }
}
</script>
