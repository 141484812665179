<template>
  <b-card :title="titleForm">
    <validation-observer ref="form">
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12" lg="12">
            <validation-provider
              #default="{ errors }"
              name="observacao"
              rules="required"
            >
              <b-form-group label="Profissional" label-for="v-profissional">
                <v-select
                  v-model="form.profissional"
                  label="nome"
                  :filterable="false"
                  :options="comboProfissionals"
                  :selected="form.profissional"
                  @search="onSearch"
                  ><template slot="no-options">
                    Pesquisa profissional por nome, cpf ou matrícula
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                </v-select>
                <small data-cy="v-profissional" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- first name -->
          <b-col cols="12" lg="6">
            <validation-provider
              #default="{ errors }"
              name="dia Inicial"
              rules="required|between:1,31"
            >
              <b-form-group label="Dia Inicial" label-for="v-diaIncial">
                <b-form-input
                  id="v-diaInicial"
                  v-model="form.diaInicial"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="dia inicial"
                />

                <small data-cy="v-diaIncial-validate" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" lg="6">
            <validation-provider
              #default="{ errors }"
              name="Hora Inicial"
              rules="required"
            >
              <b-form-group label="Hora Inicial" label-for="v-horaInicial">
                <flat-pickr
                  v-model="form.horaInicial"
                  name="fieldDate"
                  :config="{
                    altInput: true,
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'H:i',
                    time_24hr: true
                  }"
                />
                <small data-cy="v-horaInicial-validate" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" lg="6">
            <validation-provider
              #default="{ errors }"
              name="Dia Final"
              rules="required|between:1,31"
            >
              <b-form-group label="Dia Final" label-for="v-diaFinal">
                <b-form-input
                  id="v-diaFinal"
                  v-model="form.diaFinal"
                  type="number"
                  placeholder="dia final"
                  min="1"
                  max="31"
                />
                <small data-cy="v-diaFinal-validate" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" lg="6">
            <validation-provider
              #default="{ errors }"
              name="Hora Final"
              rules="required"
            >
              <b-form-group label="Hora Final" label-for="v-horaFinal">
                <flat-pickr
                  v-model="form.horaFinal"
                  name="fieldDate"
                  :config="{
                    altInput: true,
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'H:i',
                    time_24hr: true
                  }"
                />
                <small data-cy="v-horaFinal-validate" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" sm="6" md="6" lg="6">
            <b-form-group label="Quantidade de Vagas" label-for="v-vagas">
              <b-form-input
                id="v-vagas"
                v-model="form.qtoVagas"
                type="number"
                placeholder="vagas"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="form.id" cols="12" sm="6" md="6" lg="6">
            <span class="font-weight-bold">Ativo</span>
            <b-form-group>
              <b-form-checkbox
                v-model="form.ativo"
                :checked="form.ativo"
                name="check-button"
                switch
                inline
              >
                {{ form.ativo ? 'Sim' : 'Não' }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="3" lg="2">
            <span class="font-weight-bold">Domingo</span>
            <b-form-checkbox v-model="form.domingo" class="mr-2" switch />
          </b-col>

          <b-col cols="12" md="3" lg="2">
            <span class="font-weight-bold">Segunda</span>
            <b-form-checkbox v-model="form.segunda" class="mr-2" switch />
          </b-col>

          <b-col cols="12" md="3" lg="2">
            <span class="font-weight-bold">Terça</span>
            <b-form-checkbox v-model="form.terca" class="mr-2" switch />
          </b-col>

          <b-col cols="12" md="3" lg="2">
            <span class="font-weight-bold">Quarta</span>
            <b-form-checkbox v-model="form.quarta" class="mr-2" switch />
          </b-col>

          <b-col cols="12" md="3" lg="2">
            <span class="font-weight-bold">Quinta</span>
            <b-form-checkbox v-model="form.quinta" class="mr-2" switch />
          </b-col>

          <b-col cols="12" md="3" lg="2">
            <span class="font-weight-bold">Sexta</span>
            <b-form-checkbox v-model="form.sexta" class="mr-2" switch />
          </b-col>

          <b-col cols="12" md="3" lg="2">
            <span class="font-weight-bold">Sabado</span>
            <b-form-checkbox v-model="form.sabado" class="mr-2" switch />
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              class="mr-1 mt-3"
              type="reset"
              variant="outline-secondary"
              data-cy="cancel"
              @click="beforeCancelar"
            >
              Voltar
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1 mt-3"
              data-cy="save"
              @click="save"
            >
              Salvar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
import { mapState } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BFormCheckbox,
  BFormTextarea,
  BCalendar,
  BPagination,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCardGroup,
  BCardBody,
  BCardHeader,
  BListGroup,
  BListGroupItem,
  BBadge,
  BInputGroup,
  BInputGroupAppend
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import mixinsGlobal from '@/mixins'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ButtonsFormVue from '@/layouts/components/ButtonsForm.vue'
import _ from 'lodash'

export default {
  computed: {
    ...mapState('agendamentoLoteModule', {
      agendamentoLote: (state) => state.agendamentoLote
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    vSelect,
    flatPickr,
    BFormTextarea,
    BCalendar,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ButtonsFormVue,
    BCardBody,
    BCardGroup,
    BCardHeader,
    BListGroup,
    BListGroupItem,
    BBadge,
    BInputGroup,
    BInputGroupAppend
  },
  directives: {
    Ripple
  },
  mixins: [mixinsGlobal],
  data() {
    return {
      name: 'FormAgendamentoEmLote',
      value: '',
      context: null,
      titleForm: 'Agendamento Em Lote',
      seachTerm: '',
      comboProfissionals: [],
      required,
      loading: false,
      form: {
        id: null,
        diaInicial: '',
        diaFinal: '',
        horaInicial: '',
        horaFinal: '',
        profissional: null,
        ativo: true,
        qtoVagas: 0,
        repetir: '',
        domingo: false,
        segunda: true,
        terca: true,
        quarta: true,
        quinta: true,
        sexta: true,
        sabado: false,
        semana: 0,
        ativo: true
      }
    }
  },
  methods: {
    prepareForSave(data) {
      const objectSave = JSON.parse(JSON.stringify(data))
      const { diaInicial } = objectSave
      const { diaFinal } = objectSave
      if (data.id) {
        return objectSave
      }
      const obj = {
        id: objectSave.id ? objectSave.id : undefined,
        diaInicial,
        horaInicial: objectSave.horaInicial,
        horaFinal: objectSave.horaFinal,
        diaFinal,
        profissional: { id: objectSave.profissional.id },
        ativo: objectSave.ativo,
        qtoVagas: objectSave.qtoVagas,
        domingo: objectSave.domingo,
        segunda: objectSave.segunda,
        terca: objectSave.terca,
        quarta: objectSave.quarta,
        quinta: objectSave.quinta,
        sexta: objectSave.sexta,
        sabado: objectSave.sabado,
        semana: objectSave.semana,
        ativo: objectSave.ativo
      }
      return obj
    },
    save() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          const objectSave = this.prepareForSave(this.form)
          if (objectSave.id) {
            try {
              this.$store.dispatch('agendamentoLoteModule/edit', objectSave)
              this.showMessageSuccess('Editar', 'Editado com sucesso!')
              this.cancelar()
              return
            } catch (error) {
              this.showMessageSuccess('Editar', 'Falha ao editar!')
            }
          }
          this.$store
            .dispatch('agendamentoLoteModule/insert', objectSave)
            .then((response) => {
              if (response.response) {
                this.MensagemError(
                  `Falha ao Inserir o campo ${response.response.data.errors[0].fieldName}`,
                  `${response.response.data.errors[0].message}`
                )
                this.showForm = true
                return
              }
              if (response.error) {
                this.MensagemError('Falha ao Inserir', `${response.message}`)
                this.showForm = true
                return
              }
              this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
              this.cancelar()
            })
        }
      })
    },
    cancelar() {
      this.$emit('cancelar')
      this.cleanObjeto()
      this.$store.dispatch('agendamentoLoteModule/reset')
      this.$router.push({ name: 'agendamentoLote' })
    },
    beforeCancelar() {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm(
          'Deseja realmente sair do cadastro do Agendamento em Lote? Os dados não salvos serão perdidos',
          {
            title: 'Confirmação',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Sim',
            cancelTitle: 'Não',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true
          }
        )
        .then((value) => {
          this.boxTwo = value
          if (value) {
            this.cancelar()
          }
        })
    },
    cleanObjeto() {
      this.form = {
        id: null,
        diaInicial: '',
        diaFinal: '',
        horaInicial: '',
        horaFinal: '',
        profissional: null,
        ativo: true,
        qtoVagas: 0,
        domingo: false,
        segunda: true,
        terca: true,
        quarta: true,
        quinta: true,
        sexta: true,
        sabado: false,
        semana: 0,
        ativo: true
      }
    },
    onSearch(search, loading) {
      loading(true)
      this.search(loading, search, this)
    },
    search: _.debounce(async (loading, search, vm) => {
      const registrosEncontrados = await vm.$store.dispatch(
        'agendamentoLoteModule/searchProfessional',
        search
      )
      if (registrosEncontrados.response) {
        vm.MensagemError(
          'Falha na busca',
          `${registrosEncontrados.response.data.message}`
        )
        return
      }

      if (registrosEncontrados.data.length === 0) {
        vm.MensagemError('Zero profissionais', 'Nenhum profissional encontrado')
        return
      }

      vm.comboProfissionals = registrosEncontrados.data.content.map((item) => ({
        id: item.id,
        nome: item.nome
      }))
      loading(false)
    }, 350)
  },
  async mounted() {
    this.cleanObjeto()
    this.loading = false
    if (this.$route.params.id) {
      await this.$store.dispatch(
        'agendamentoLoteModule/getById',
        this.$route.params.id
      )
    }
    this.form = JSON.parse(JSON.stringify(this.agendamentoLote))
    this.loading = false
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
